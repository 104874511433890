import React, { createContext, useState, ReactNode, useContext } from 'react';

// Define the shape of the context
interface ServiceContextType {
  title: string;
  body: string;
  imgSource: string;
  setData: () => void;
}

// Create a default context value
const defaultContext: ServiceContextType = {
  title: "",
  body: "",
  imgSource: "",
  setData: () => {}
};

// Create the context
const ServiceContext = createContext<ServiceContextType>(defaultContext);

// Create a provider component
export const ServiceProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [data, setData] = useState<ServiceContextType>({
    title: "",
    body: "",
    imgSource: "",
    setData: () => {}
  });
  const initialData = {
    title: "",
    body: "",
    imgSource: "",
  }
  const onSetData = (data: ServiceContextType) => {
    console.log("...working", data);
  };
  return (
    <ServiceContext.Provider value={{ ...initialData, setData: onSetData }}>
      {children}
    </ServiceContext.Provider>
  );
};

// Custom hook to use the context
export const useService = () => useContext(ServiceContext);
